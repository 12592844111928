import React, { useEffect, useRef, useState } from 'react';
import { Step } from 'components/steps/Step';
import { Castle } from 'components/steps/Address';
import RSVP from 'components/steps/RSVP';
import FormComponent from 'components/form/Weddings';
import { Header } from 'components/Home/Header';
import HomeImage from 'components/Home/HomeImage';
import { Invitation } from 'components/steps/Invitation';
import { useParams } from 'react-router-dom';
import { useUser } from 'context/user';
import { Button, CircularProgress } from '@mui/joy';
import factory from '../assets/factory-58-2.jpeg'
import { Helmet } from 'react-helmet';
import image from '../assets/imagesitew.jpeg';

export const handleScroll = (ref: any) => {
  window.scrollTo({
    top: ref.offsetTop,
    left: 0,
    behavior: "smooth",
  });
};

const handleRefresh = () => {
  window.location.reload(); // Reloads the current page
};

type JoySpinnerProps = {
  uuid: string | undefined;
  showMessage: boolean;
}


const JoySpinner = ({ uuid, showMessage }: JoySpinnerProps) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-beige">
      {uuid ? (
        <>
          <CircularProgress color="primary" />
          {showMessage &&
          <div className="mt-10 text-center max-w-2xl">
            Chargement... Si ce message reste trop longtemps (plus de 10 secondes), rafraîchissez la page. Vérifiez que vous avez bien cliqué sur le lien WhatsApp (n'entrez pas le lien manuellement). Sinon, contactez Gabriel. 🐛😉
            <div className='mt-10 flex gap-2 justify-center'>
              <button className="bg-whatsapp-green text-white mt-4 px-6 py-2 rounded hover:bg-whatsapp-darkgreen" onClick={redirectToWhatsApp}>
                Contacter Gabriel
              </button>
              <button className="bg-cyan-blue text-white mt-4 px-6 py-2 rounded hover:bg-whatsapp-darkgreen" onClick={handleRefresh}>
                Rafraîchir la page
              </button>
            </div>
          </div>
          }
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <p className="text-lg text-center max-w-500">
            Le lien est incorrect 😥 Veuillez cliquer sur le lien envoyé par WhatsApp/SMS 🙏
            <div>
              <button className="bg-whatsapp-green text-white mt-4 px-6 py-2 rounded hover:bg-whatsapp-darkgreen" onClick={redirectToWhatsApp}>
                Ouvrir WhatsApp
              </button>
            </div>
          </p>
          </div>
      )}
    </div>
  );
};


const redirectToWhatsApp = () => {
  const phoneNumber = '+33672674997';
  const whatsappUrl = `whatsapp://send?phone=${phoneNumber}`;
  window.location.href = whatsappUrl;
};



function Home() {
  const { uid } = useParams();
  const { formData } = useUser();

  useEffect(() => {
    sessionStorage.setItem("uuid", uid!);
  }, [uid]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(true);
    }, 3000); // Show the message after 3 seconds

    return () => clearTimeout(timeout);
  }, []);

  const [loading, setLoading] = useState(true);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const scrollDeroulement = useRef<HTMLDivElement | null>(null);
  const scrollAdress = useRef<HTMLDivElement | null>(null);
  const scrollResponse = useRef<HTMLDivElement | null>(null);
  const [showMessage, setShowMessage] = useState(false); // New state to control showing the message

  const scrollToInvitation = () => {
    if (scrollRef.current) {
      console.log(scrollRef.current)
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    console.log(uid)
    console.log(formData);
    if (formData.firstName !== '') {
      setLoading(false)
    }
  }, [formData, formData.firstName, uid]);

  if (loading) {
    // Display a loading spinner or any loading text
    return <JoySpinner uuid={uid} showMessage={showMessage} />;
  }


  return (
    <>
      <Helmet>
        <meta property="og:title" content="Henne Anaël & Gabriel" />
        <meta property="og:image" content={'https://ketubahazoulayart.com/wp-content/uploads/2020/04/moroccan-jewish-wedding-e1587643891181.jpg'} />
      </Helmet>
      <div className="bg-beige h-screen" style={{ margin: '0px', padding: '0px' }}>
        <Header invitationRef={scrollRef} stepRef={scrollDeroulement} addressRef={scrollAdress} responseRef={scrollResponse} />

        <div style={{ backgroundColor: 'red' }}>
          <HomeImage scrollToInvitation={scrollToInvitation} backgroundImage={image} />
        </div>
        <div>
          <Invitation innerRef={scrollRef} />
        </div>
        <Step innerRef={scrollDeroulement} />
        <Castle innerRef={scrollAdress} imageSrc={factory} henne={false} />
        {formData.firstName !== 'Generique' &&
        <RSVP innerRef={scrollResponse} />
        }
      </div>
    </>
  );
}

export default Home;
