import { faWaze } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlackBar from "components/generic/BlackBar";

type StepProps = {
  innerRef: React.MutableRefObject<HTMLDivElement | null>
};

export function Step({ innerRef }: StepProps) {
  return (
    <>
      <div ref={innerRef}
        style={{
          fontFamily: 'Great Vibes',
          fontSize: '3em',
          display: 'flex',
          justifyContent: 'center',
          color: 'black',
          paddingBottom: '20px'
        }}
      >
        Le déroulé
      </div>
      <div className="ml-[20px]">
      <div className="flex-col">
        <div className="font-bold">
            4/07/2024
        </div>
          <div className="">
            📍<a href="https://www.google.com/maps/place/Mairie+du+19%C3%A8me+arrondissement+de+Paris/@48.8829193,2.3794076,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66ddaa2400b67:0x334473c7ba88933d!8m2!3d48.8829193!4d2.3819825!16s%2Fg%2F12338f7b?entry=ttu" target="_blank" rel="noreferrer">Mairie du 19ème</a>
          </div>
          <ul className="steps steps-vertical">
            <li data-content="🏛️" className="step"><div>
              <div className="flex">
                15:00
              </div>
              <div>
                Mariage civil
              </div>
            </div></li>
          </ul>
          <div className="font-bold mt-5">
            10/07/2024
          </div>
          <div className="mb-0">
            📍<a href="https://www.google.com/maps/place/factory+58/@48.9039575,2.4240384,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66d4cc308b861:0x3e4f16593ec298b6!8m2!3d48.9039575!4d2.4266133!16s%2Fg%2F11hds0jf5x?entry=ttu" target="_blank" rel="noreferrer">Factory 58</a>
          </div>
        <div>
          <ul className="steps steps-vertical">
            <li data-content="🛎️" className="step"><div>
              <div className="flex">
                  17:00
              </div>
              <div>
                  Réception
              </div>
            </div></li>
              <li data-content="💍" className="step"><div>
                <div className="flex">
                  17:30
                </div>
                <div>
                  Houppa
                </div>
              </div></li>
            <li data-content="🍹" className="step"><div>
              <div className="flex">
                  19:00
              </div>
              <div>
                  Cocktail et Réception
                </div>
              </div>
              </li>
          </ul>
        </div>
      </div>
      </div>
      <BlackBar />
    </>
  )
}
