import React, { useEffect, useState } from 'react';
import CountdownTimer from './Counter';
import Confetti from 'react-dom-confetti'; // Import the Confetti component

const yourConfettiConfig = {
  angle: 0,
  spread: 860,
  startVelocity: 40,
  elementCount: 300,
  dragFriction: 0.2,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  colors: ["#e1e1e1", "#ffd700", "#ffffff", "#f1f1f1"],
};

type HomeImageProps = {
  scrollToInvitation: () => void;
  backgroundImage: string;
  disableCountdownTimer?: boolean;
};

function HomeImage({ scrollToInvitation, backgroundImage, disableCountdownTimer }: HomeImageProps) {
  const [confettiActive, setConfettiActive] = useState(false); // Add state variable

  const activateConfetti = () => {
    setConfettiActive(true);
    setTimeout(() => {
      setConfettiActive(false);
    }, 3000); // Hide the confetti after 3 seconds (adjust as needed)
  };

  return (
    <div
      className="bg-beige h-screen"
      style={{
        height: '92vh',
        backgroundPositionY: '50%',
        backgroundImage: `url(${backgroundImage})`, // Use dynamic background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div style={{ bottom: '100px' }} className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        {!disableCountdownTimer && <CountdownTimer />} {/* Render CountdownTimer only if disableCountdownTimer is not true */}
        <Confetti active={confettiActive} config={yourConfettiConfig} />

        <div style={{ bottom: !disableCountdownTimer ? '-80px' : '0px', width: disableCountdownTimer ? '300px' : '300px' }} className="absolute left-1/2 transform -translate-x-1/3 -translate-y-1/2">
          <button className="btn btn-active bg-beige" onClick={() => {
            scrollToInvitation();
            activateConfetti(); // Activate confetti
          }}>
            Voir mon invitation
          </button>
        </div>
      </div>
    </div>
  );
}

export default HomeImage;
