import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Invite } from 'context/user';
import { auth, db } from 'Firebase';
import { Link } from 'react-router-dom';
import { collection, getDocs, doc, getDoc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

type AnswerRow = Invite & { link: string, linkHenne: string, answers?: any };
interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    ((Number(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15) >> Number(c) / 4).toString(16)
  );
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = uuidv4();
    setRows((oldRows) => [...oldRows, { id, flagHenne: false, flagChabatHatan: false, phoneNumber: '', invitationSent: false, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
      <GridToolbarExport />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

const handleSendInvitation = (
  phoneNumber: string,
  name: string,
  link: string,
  whoInvited: string
) => {
  console.log(phoneNumber);
  console.log(name)
  console.log(link)
  console.log(whoInvited)
  // Implement the logic to send a WhatsApp message here
  // You may use a library like `react-router-dom` to navigate to a new page with the WhatsApp message
  // or open the WhatsApp web link. Adjust the logic based on your requirements.
  let message = '';
  message = `Bonjour ${name},

C'est avec une immense joie et gratitude envers Hachem que nous vous convions à notre mariage qui aura lieu bzH le *Mercredi 10 Juillet 2024*

C'est un moment spécial et nous souhaitons de tout cœur le partager avec vous ✨

Merci de nous confirmer votre présence dès réception : ${link}

Nous comptons sur votre présence !

Anaël et Gabriel 👰🤵

PS : Chaque lien est nominatif`;

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  console.log(whatsappLink)
  // Open WhatsApp link in a new window or navigate to it
  window.open(whatsappLink, '_blank');
};

const handleSendInvitation2 = (
  phoneNumber: string,
  name: string,
  link: string,
  whoInvited: string
) => {
  // Implement the logic to send a WhatsApp message here
  // You may use a library like `react-router-dom` to navigate to a new page with the WhatsApp message
  // or open the WhatsApp web link. Adjust the logic based on your requirements.
  let message = '';
  message = `Bonjour ${name},

C'est avec une immense joie et gratitude envers Hachem que les familles Soudry et Dray vous invitent au mariage de leur enfant Gabriel et Anaël qui aura lieu bzH le *Mercredi 10 Juillet 2024*

C'est un moment spécial et nous souhaitons de tout cœur le partager avec vous ✨

Merci de nous confirmer votre présence dès réception : ${link}

Nous comptons sur votre présence !`

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  // Open WhatsApp link in a new window or navigate to it
  window.open(whatsappLink, '_blank');
};


const handleSendHenneInvitation = (
  phoneNumber: string,
  name: string,
  link: string,
  whoInvited: string
) => {
  // Implement the logic to send a WhatsApp message here
  // You may use a library like `react-router-dom` to navigate to a new page with the WhatsApp message
  // or open the WhatsApp web link. Adjust the logic based on your requirements.
  let message = '';
  message = `Nous comptons également sur votre présence pour la soirée du *Henné* qui suivra le mariage civil le *Jeudi 4 Juillet 2024*.
Merci de confirmer votre présence sur ce lien : ${link}
PS : Chaque lien est nominatif`;
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  // Open WhatsApp link in a new window or navigate to it
  window.open(whatsappLink, '_blank');
};

const user = auth.currentUser;
console.log(user);
const fetchUsers = async () => {
  const invitesCollection = collection(db, 'invites');
  const querySnapshot = await getDocs(invitesCollection);

  const inviteIds = querySnapshot.docs.map(doc => doc.id);

  // Create an array of promises to fetch answers in batch
  const answersPromises = inviteIds.map(id => getDoc(doc(db, 'answers', id)));

  const answersSnapshots = await Promise.all(answersPromises);

  const rows = querySnapshot.docs.map((document, index) => {
    const data = document.data();
    const answersDocSnap = answersSnapshots[index];
    const answers = answersDocSnap.exists() ? answersDocSnap.data() : {};

    return {
      id: document.id,
      firstName: data.firstName,
      lastName: data.lastName,
      flagHenne: data.flagHenne,
      flagWedding: true,
      flagChabatHatan: data.flagChabatHatan,
      phoneNumber: data.phoneNumber,
      link: `${window.location.origin}/${document.id}`,
      linkHenne: `https://www.henne.mariage-anael-gabriel.fr/${document.id}`,
      invitationSent: data.invitationSent,
      invitBy: data.invitBy,
      answers: answers,
    };
  });

  console.log(rows);
  return rows;
};

export default function AdminPage() {
  const [rows, setRows] = React.useState<(Invite & { link: string, answers?: any, isNew?: boolean })[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});


  //@ts-ignore
  const getRowClassName = (params) => {
    if (params.row.flagHenne) {
      return params.row && params.row.answers && params.row.answers.finished && params.row.answers.isFinishedHenne ? 'green-row' : '';
    }
    return params.row && params.row.answers && params.row.answers.finished ? 'green-row' : '';
  };


  const columns: GridColDef[] = [

    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 200,
      editable: true,
      resizable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 200,
      editable: true,
      resizable: true,
    },
    {
      field: 'flagHenne',
      headerName: 'Henné',
      type: 'boolean',
      width: 60,
      editable: true,
    },
    {
      field: 'flagChabatHatan',
      headerName: 'Chabat',
      type: 'boolean',
      width: 60,
      editable: true,
    },
    {
      field: 'phoneNumber',
      headerName: 'Téléphone',
      type: 'string',
      width: 150,
      editable: true,
    },
    {
      field: 'invitBy',
      headerName: 'invitBy',
      width: 110,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Anaël', 'Gabriel'],
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 70,
      renderCell: (params) => (
        <Link to={params.value}>{params.value}</Link>
      ),
    },
    {
      field: 'linkHenne',
      headerName: 'LinkHenne',
      width: 70,
      renderCell: (params) => (
        <Link to={params.value}>{params.value}</Link>
      ),
    },
    {
      field: 'henneAnswer',
      headerName: 'Henne(R)',
      type: 'number',
      width: 90,
      valueGetter: (params: any, row: any) => {
        console.log(row)
        return row?.answers?.henne === undefined ? '' : row?.answers.henne
      },
    },
    {
      field: 'weddingAnswer',
      headerName: 'Wedding(R)',
      type: 'number',
      width: 90,
      valueGetter: (params: any, row: any) => row?.answers?.wedding === undefined ? '' : row?.answers.wedding,
    },
    {
      field: 'finished',
      headerName: 'finished',
      type: 'boolean',
      width: 90,
      valueGetter: (params: any, row: any) => row.answers?.finished || '',
    },
    {
      field: 'isFinishedHenne',
      headerName: 'isFinishedHenne',
      type: 'boolean',
      width: 90,
      valueGetter: (params: any, row: any) => row.answers?.isFinishedHenne || '',
    },
    {
      field: 'invitationSent',
      headerName: 'isSent',
      type: 'boolean',
      width: 40,
      editable: true,
    },
    {
      field: 'sentInvitationButton',
      headerName: 'Send Mariage',
      width: 90,
      renderCell: (params) => (
        <button
          onClick={() => handleSendInvitation(params?.row?.phoneNumber, params.row?.firstName, params.row?.link, params.row?.invitBy)}
          disabled={params.row.invitationSent}
        >
          Send
        </button>
      ),
    },
    {
      field: 'sentInvitationButton2',
      headerName: 'Send MEssage 2',
      width: 90,
      renderCell: (params) => (
        <button
          onClick={() => handleSendInvitation2(params?.row?.phoneNumber, params.row?.firstName, params.row?.link, params.row?.invitBy)}
          disabled={params.row.invitationSent}
        >
          Custom
        </button>
      ),
    },
    {
      field: 'sentHenneButton',
      headerName: 'Send Henne',
      width: 90,
      renderCell: (params) => (
        <button
          onClick={() =>
            handleSendHenneInvitation(
              params.row.phoneNumber,
              params.row.firstName,
              params.row.linkHenne,
              params.row.invitBy
            )
          }
          disabled={params?.row?.invitationSent}
        >
          Send Hene
        </button>
      ),
    },
    {
      field: 'comment',
      headerName: 'comment',
      width: 500,
      resizable: true,
      valueGetter: (params: any, row: any) => row.answers?.comment || '',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key='Save'
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key='Cancel'
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={() => handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key='Edit'
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key='Delete'
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const fetchData = async () => {
    const data = await fetchUsers();
    setRows(data);
  };

  React.useEffect(() => {
    fetchData();
  }, []); // Runs only on component mount

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => async () => {
    try {
      //const newInviteRef = doc(collection(db, 'invites'));
      //const editedRow = rows.find((row) => row.id === id);
      //console.log(editedRow)
      //console.log("==============")
      //await setDoc(newInviteRef, editedRow);
    } catch (error) {
      console.error('Error creating new invite:', error);
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };


  const handleDeleteClick = async (id: GridRowId) => {
    try {
      // Create a reference to the document in the 'invites' collection with the specified id
      const inviteRef = doc(collection(db, 'invites'), String(id));
      console.log(inviteRef)
      await deleteDoc(inviteRef);
      // Remove the row with the specified id from the 'rows' array
      setRows(rows.filter((row) => row.id !== id));
      await fetchData()
    } catch (error) {
      console.error('Error deleting invite:', error);
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    console.log(newRow);
    const updatedRow = { ...newRow, isNew: false } as unknown as AnswerRow;

    try {
      const inviteRef = doc(collection(db, 'invites'), newRow.id);

      // Check if the document with the specified ID exists
      const inviteDoc = await getDoc(inviteRef);

      if (inviteDoc.exists()) {
        // If the document exists, update it
        await updateDoc(inviteRef, updatedRow as Record<string, any>);
      } else {
        // If the document does not exist, create it
        await setDoc(inviteRef, updatedRow);
      }

      // Fetch data after updating or creating the document
      await fetchData();
    } catch (error) {
      console.log(error);
    }

    // Update the local rows array
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Fetch data again (not sure if you need this here, depends on your use case)
    await fetchData();

    return updatedRow;
  };


  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
    };

  return (
    <div className='bg-beige h-screen'>
      <Box sx={{ paddingTop: '30px', height: '80%', width: '100%' }}>
        <DataGrid
          getRowClassName={getRowClassName}
          onRowEditStop={handleRowEditStop}
          onRowModesModelChange={handleRowModesModelChange}
          rowModesModel={rowModesModel}
          editMode="row"
          processRowUpdate={processRowUpdate}
          density='compact'
          slots={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            toolbar: EditToolbar,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          slotProps={{
            toolbar: {
              setRows, setRowModesModel, showQuickFilter: true,
            },
          }}
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            boxShadow: 10,
            border: 10,
            borderColor: 'primary.light',
            background: 'white',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}

          checkboxSelection
          disableRowSelectionOnClick
        />

        <style>{`
        .green-row {
          background-color: lightgreen;
        }
      `}</style>
      </Box>
    </div>
  );
}

