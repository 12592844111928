/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate } from "react-router-dom";
import { Header } from "components/Home/Header";
import chabat from "../../assets/image0.jpeg"
import { useEffect, useState } from "react";
import React from "react";

import Select, { SelectChangeEvent } from '@mui/material/Select';

import Radio from '@mui/joy/Radio';
import { Button } from "@mui/joy";
import { useUser } from "context/user";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { TextField } from "@mui/material";
interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const Comment = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const [age, setAge] = React.useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { setAnswer, answer } = useUser()
  const uuid = sessionStorage.getItem("uuid");

  const [commentUser, setCommentUser] = useState('');

  useEffect(() => {
    if (answer.comment != '') {
      setCommentUser(answer.comment);
    }
  }, [answer])

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  const handleRSVPClick = () => {
    setAnswer((prev) => ({ ...prev, comment: commentUser }));
    navigate(`/result/${uuid}`);
  };


  useEffect(() => {
    // Enable the button only if "Oui" is selected and the number of persons is selected
    console.log(selectedOption)
    console.log(age)

    setIsButtonDisabled(selectedOption === '' || (selectedOption === 'Oui' && age === ''));
  }, [selectedOption, age]);

  const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  };
  return (
    <>
      <div style={{}}>
        <Header invitationRef={undefined} stepRef={undefined} addressRef={undefined} responseRef={undefined} />
      </div>

      <div className="pt-20 w-full mt-[0px] flex justify-center">
        <img
          src={chabat}
          alt="logo"
          className="w-full object-cover"
          style={{
            width: '100%', height: "380px", objectPosition: 'center bottom', maxWidth: "350px", justifyContent: 'center', display: 'flex', alignItems: 'center',
          }}
        />
      </div>

      <div style={{ position: 'absolute', top: "340px" }}>

      </div>
      <div className="flex-col justify-center items-center w-full   bg-white px-10 py-5">
        <h1 className="text-center text-l sm:text-xl font-semibold text-[#4A07DA]">
          Un mot pour les mariés ? 🥰 (Facultatif)
        </h1>

        <div className="pt-[50px]">
          <TextField multiline rows={3}
            value={commentUser}
            onChange={(e) => {
              setCommentUser(e.target.value);
            }}
            maxRows={5} style={{ width: '100%', }}>

          </TextField>
        </div>
        <div className="w-full mt-5 sm:mt-8">
          <div className="mx-auto w-full sm:max-w-md md:max-w-lg flex flex-col gap-3">
            <div className="flex flex-col sm:flex-row gap-3">
            </div>

            <div className="flex flex-col md:flex-row gap-2 md:gap-4 justify-center items-center">
              <Button
                color="primary"
                onClick={handleRSVPClick}
                variant="solid"
                style={{ width: '230px' }}
              >
                Confirmer votre réponse
              </Button>
            </div>
          </div>
        </div>
      </div >

    </>
  )
};

export default Comment;
