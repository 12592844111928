import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import 'Routes' instead of 'Switch'
import Home from 'Pages/Page1';
import Wedding from 'components/form/Weddings';
import Chabat from 'components/form/Chabat';
import Comment from 'components/form/Comment';
import ThankYouPage from 'components/form/onComplete';
import { UserProvider } from 'context/user';
import SignIn from 'Pages/Admin/SignIn';
import PreviewImage from 'PreviewImage';
import Stat from 'Pages/Admin/Stat';

function App() {
  return (
    <Router>
      <UserProvider>
        <PreviewImage />
        <Routes>
          <Route path="/:uid?" element={<Home />} />
          <Route path="/rsvp/wedding/:uid?" element={<Wedding />} />
          <Route path="/rsvp/chabat/:uid?" element={<Chabat />} />
          <Route path="/comment/:uid?" element={<Comment />} />
          <Route path="/result/:uid?" element={<ThankYouPage />} />
          <Route path="/adminInvit" element={<SignIn />} />
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
