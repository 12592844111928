/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line import/no-unused-modules

import { handleScroll } from "Pages/Page1";
import { Invitation } from "../steps/Invitation";
import { Link } from "react-router-dom";
import Confetti from "react-dom-confetti";
import { useState } from "react";
import { useUser } from "context/user";

type HomeImageProps = {
  invitationRef: any;
  stepRef: any;
  addressRef: any;
  responseRef: any;
};

const yourConfettiConfig = {
  angle: 0,
  spread: 860,
  startVelocity: 40,
  elementCount: 300,
  dragFriction: 0.2,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  colors: ["#e1e1e1", "#ffd700", "#ffffff", "#f1f1f1"],
};


export function Header({ invitationRef, stepRef, addressRef, responseRef }: HomeImageProps) {
  const [confettiActive, setConfettiActive] = useState(false); // Add state variable
  const uuid = sessionStorage.getItem("uuid");

  const activateConfetti = () => {
    setConfettiActive(true);
    setTimeout(() => {
      setConfettiActive(false);
    }, 3000); // Hide the confetti after 3 seconds (adjust as needed)
  };

  const pleasantTextStyle = {
    color: '#black', // Soft gold color
  };


  return (
    <div className="navbar bg-base-100 top-0 z-50 fixed">
      <Confetti active={confettiActive} config={yourConfettiConfig} />

      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost btn-circle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <Link to={`/${uuid}`} onClick={() => {
                handleScroll(invitationRef.current);
                activateConfetti();
              }}>Invitation</Link>
            </li>
            <li>
              <Link to={`/${uuid}`} onClick={() => {
                handleScroll(stepRef.current);
              }}>Le déroulé</Link>
            </li>
            <li>
              <Link to={`/${uuid}`} onClick={() => {
                handleScroll(addressRef.current);
              }}>{"L'adresse"}</Link>
            </li>
            <li>
              <Link to={`/${uuid}`} onClick={() => {
                handleScroll(responseRef.current);
              }}>{"Votre réponse"}</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-center">
        <Link to={`/${uuid}`} onClick={() => { handleScroll(addressRef.current); }} style={pleasantTextStyle}>
          <span className="btn btn-ghost normal-case text-xl">
            {"Mariage Anaël et Gabriel"}
          </span>
        </Link>
      </div>
      <div className="navbar-end"></div>
    </div>
  );
}
