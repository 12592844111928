/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate } from "react-router-dom";
import { Header } from "components/Home/Header";
import chabat from "../../assets/chabat.jpeg"
import { useEffect, useState } from "react";
import { db } from "Firebase";
import { collection, getDocs } from "firebase/firestore";
import TextField from '@mui/material/TextField';
import React from "react";
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import RadioGroup from '@mui/joy/RadioGroup';
import SentimentDissatisfied from '@mui/icons-material/SentimentDissatisfied';
import Celebration from '@mui/icons-material/Celebration';
import Apartment from '@mui/icons-material/Apartment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Radio from '@mui/joy/Radio';
import { Button } from "@mui/joy";
import { useUser } from "context/user";
import { AddToCalendarButton } from "add-to-calendar-button-react";
interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const Chabat = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const [age, setAge] = React.useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { setAnswer } = useUser()
  const uuid = sessionStorage.getItem("uuid");

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  const handleRSVPClick = () => {
    if (selectedOption === 'Non') {
      setAnswer((prev) => ({ ...prev, chabbatHatan: 0 }));
    }
    else {
      setAnswer((prev) => ({ ...prev, chabbatHatan: +age }));
    }
    navigate(`/comment/${uuid}`);
  };


  useEffect(() => {
    // Enable the button only if "Oui" is selected and the number of persons is selected
    console.log(selectedOption)
    console.log(age)

    setIsButtonDisabled(selectedOption === '' || (selectedOption === 'Oui' && age === ''));
  }, [selectedOption, age]);

  const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  };
  return (
    <>
      <div style={{}}>
        <Header invitationRef={undefined} stepRef={undefined} addressRef={undefined} responseRef={undefined} />
      </div>

      <div className="pt-20 w-full mt-[-40px]">
        <img
          src={chabat}
          alt="logo"
          className="w-full object-cover"
          style={{
            width: '100%', height: "380px", objectPosition: 'center bottom'
          }}
        />
      </div>

      <div style={{ position: 'absolute', top: "340px" }}>
        <AddToCalendarButton
          name="Chabat Hatan "
          description="Hâte de vous voir :) "
          startDate="2024-07-12"
          endDate="2024-07-12"
          timeZone="Europe/Paris"
          buttonStyle="date"
          location="Torah Temima (Les Lilas)"
          organizer="Gabriel SOUDRY|gabriel_s@hotmail.fr"
          availability="busy"
          options={['Apple', 'Google', 'iCal', 'Outlook.com']}
          hideIconModal
          size="12"
          label="Mairie/Henne🏫"
          language="fr"
        ></AddToCalendarButton>
      </div>
      <div className="flex-col justify-center items-center w-full   bg-white px-10 py-5">
        <h1 className="text-center text-xl sm:text-xl font-semibold text-[#4A07DA]">
          Confirmer votre présence
          Chabat Hatan
        </h1>

        <div className="w-full mt-5 sm:mt-8">
          <div className="mx-auto w-full sm:max-w-md md:max-w-lg flex flex-col gap-3">
            <div className="flex flex-col sm:flex-row gap-3">

            </div>

            Seriez vous present ?
            <div className="flex-row mb-5 mt-[-10px]">
              <RadioGroup aria-label="Your plan" name="people" defaultValue="Individual" className="flex flex-row">

                <List
                  style={{ display: 'flex', flexDirection: 'row', padding: 0, gap: '10px' }}
                  className="flex flex-row"
                  sx={{
                    '--List-gap': '0.1rem',
                    '--ListItem-paddingY': '1rem',
                    '--ListItem-radius': '8px',
                    '--ListItemDecorator-size': '32px',
                  }}
                >
                  {
                    ['Non', 'Oui'].map((item, index) => (
                      <ListItem variant="outlined" key={item} sx={{ boxShadow: 'sm' }}>
                        <ListItemDecorator>
                          {[<SentimentDissatisfied />, <Celebration />][index]}
                        </ListItemDecorator>
                        <div className="flex flex-row">
                          <Radio
                            className="flex flex-row"
                            overlay
                            value={item}
                            label={item}
                            onChange={handleOptionChange}
                            sx={{ flexDirection: 'row-reverse' }}
                            slotProps={{
                              action: ({ checked }) => ({
                                sx: (theme) => ({
                                  ...(checked && {
                                    inset: -1,
                                    border: '3px solid',
                                    borderColor: 'blue',
                                  }),
                                }),
                              }),
                            }}
                          />
                        </div>
                      </ListItem>
                    ))}
                </List>
              </RadioGroup>
            </div>
            {selectedOption === 'Oui' && (
              <div className="mt-[-20px] ml-[-10px] mb-[5px]">
                <FormControl sx={{ m: 1, minWidth: 300 }}>
                  <InputLabel id="demo-simple-select-helper-label">Nombre de personnes</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={age}
                    label="Nombre de personnes"
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
          <div className="flex flex-col md:flex-row gap-2 md:gap-4 justify-center items-center">
            <Button
              color="primary"
              onClick={handleRSVPClick}
              variant="solid"
              disabled={isButtonDisabled}
              style={{ width: '230px' }}
            >
              Confirmer
            </Button>
          </div>

        </div>
      </div >
    </>
  );
};

export default Chabat;
