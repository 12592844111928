/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import './App.css'
import { db } from "Firebase";
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { Container, Grid, Paper, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

export interface Invite {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  flagWedding?: boolean;
  flagChabatHatan: boolean;
  flagHenne: boolean;
  invitBy: string;
  invitationSent: boolean;
}

interface Answer {
  from: string,
  wedding: number,
  comment: string,
  finished: boolean,
  isFinishedHenne: boolean,
  henne: number;
}

const fetchUsers = async () => {
  const invitesCollection = collection(db, 'invites');
  const querySnapshot = await getDocs(invitesCollection);

  const inviteIds = querySnapshot.docs.map(doc => doc.id);

  // Create an array of promises to fetch answers in batch
  const answersPromises = inviteIds.map(id => getDoc(doc(db, 'answers', id)));

  const answersSnapshots = await Promise.all(answersPromises);

  const rows = querySnapshot.docs.map((document, index) => {
    const data = document.data();
    const answersDocSnap = answersSnapshots[index];
    const answers = answersDocSnap.exists() ? answersDocSnap.data() : {};

    return {
      id: document.id,
      firstName: data.firstName,
      lastName: data.lastName,
      flagHenne: data.flagHenne,
      flagWedding: true,
      flagChabatHatan: data.flagChabatHatan,
      phoneNumber: data.phoneNumber,
      link: `${window.location.origin}/${document.id}`,
      linkHenne: `https://www.henne.mariage-anael-gabriel.fr/${document.id}`,
      invitationSent: data.invitationSent,
      invitBy: data.invitBy,
      answers: answers,
    };
  });

  console.log(rows);
  return rows;
};

function Stat() {
  const [rows, setRows] = useState<(Invite & { answers?: Answer })[]>([]);
  const [henneCount, setHenneCount] = useState(0);
  const [henneFinished, setHenneFinished] = useState(0);
  const [weddingFinished, setWeddingFinished] = useState(0);

  const [weddingCount, setWeddingCount] = useState(0);
  const [weddingResponse, setWeddingResponse] = useState(0);
  const [henneResponse, setHenneResponse] = useState(0);
  const [filter, setFilter] = useState<string>('All');
  const [filteredRows, setFilteredRows] = useState<(Invite & { answers?: Answer })[]>([]);

  const fetchData = async () => {
    const data = await fetchUsers();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setRows(data);
  };

  const applyFilter = (data: (Invite & { answers?: Answer })[], filter: string) => {
    let filteredData = data;
    if (filter === 'Gabriel') {
      filteredData = data.filter(row => row.invitBy === 'Gabriel');
    }
    else if (filter === 'Anaël') {
      filteredData = data.filter(row => row.invitBy === 'Anaël');
    }
    setFilteredRows(filteredData);

    const henneAnswers = filteredData.reduce((count, row) => count + (row.flagHenne ? 1 : 0), 0);
    const weddingAnswers = filteredData.reduce((count, row) => count + (row.flagWedding ? 1 : 0), 0);

    const henneFinished = filteredData.reduce((count, row) => count + (row.answers?.isFinishedHenne ? 1 : 0), 0);
    const weddingFinished = filteredData.reduce((count, row) => count + (row.answers?.finished ? 1 : 0), 0);

    const weddingResponse = filteredData.reduce((count, row) => count + ((row.answers?.wedding ?? 0) > 0 ? (row.answers?.wedding ?? 0) : 0), 0);
    const henneResponse = filteredData.reduce((count, row) => count + ((row.answers?.henne ?? 0) > 0 ? (row.answers?.henne ?? 0) : 0), 0);


    setHenneCount(henneAnswers);
    setWeddingCount(weddingAnswers);
    setWeddingResponse(weddingResponse);
    setHenneResponse(henneResponse);
    setHenneFinished(henneFinished);
    setWeddingFinished(weddingFinished);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    applyFilter(rows, filter);
  }, [filter, rows]);

  const handleFilterChange = (event: any) => {
    setFilter(event.target.value as string);
  };

  return (
    <Container style={{ backgroundColor: 'grey', paddingTop: "10px", width: '900px', height: '300px' }}>
      <FormControl fullWidth style={{ "paddingBottom": "10px" }}>
        <InputLabel id="filter-label">Filter</InputLabel>
        <Select
          style={{ backgroundColor: "white" }}
          labelId="filter-label"
          value={filter}
          onChange={handleFilterChange}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Gabriel">Gabriel</MenuItem>
          <MenuItem value="Anaël">Anaël</MenuItem>
        </Select>
      </FormControl>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h5">Total Invites</Typography>
            <Typography variant="h6">{filteredRows.length}</Typography>
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h5">Nombre invitation Henne</Typography>
            <Typography variant="h6">{henneFinished}/{henneCount}</Typography>
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h5">Nombre invitaiton Mariage</Typography>
            <Typography variant="h6">{weddingFinished}/{weddingCount}</Typography>
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h5">Nombre de personne au Mariage</Typography>
            <Typography variant="h6">{weddingResponse}</Typography>
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h5">Nombre de personne au Henne</Typography>
            <Typography variant="h6">{henneResponse}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Stat
