/* eslint-disable import/no-unused-modules */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
import BlackBar from "../generic/BlackBar";
import React from 'react';
import image from "../../assets/col-removebg-preview.png"
import image2 from "../../assets/backgroundOpacity0.7.png"

import reception from "../../assets/mairieInvitation.png"
import "./style.css"
type InvitationProps = {
  innerRef: React.MutableRefObject<HTMLDivElement | null>;
};

export function Invitation({ innerRef }: InvitationProps) {
  const goldColor = '#ba9c18'

  const curvedTextStyles = {
    fontFamily: 'addingtoncf-thin',
    fontSize: '1.3em', // Adjust the font size as needed
  };
  const bigGoldTextStyles = {
    fontFamily: 'YourGoldFont', // Replace 'YourGoldFont' with your chosen gold handwritten style font
    fontSize: '2em', // Adjust the font size for the big text
    color: goldColor,
    display: 'flex',
  };

  return (
    <>

      <div style={{
        backgroundColor: 'rgb(244, 243, 235)', height: '590px', borderColor: goldColor, borderStyle: "solid", borderWidth: "2px", position: "relative", display: "flex", justifyContent: "center", margin: "10px"
      }}>
        <div className="myDiv" ref={innerRef}>
          <p style={{ margin: 0, fontSize: "4em", fontFamily: 'Great Vibes', color: "#ba9c18" }}>Mairie</p>
        </div >
        <img style={{ maxHeight: "580px", zIndex: 0 }} src={reception} alt="Reception"></img>
      </div >
      <div style={{ padding: '0 10px', height: '662px' }}>
        <div style={{
          padding: '0 3px',
          height: '650px',
          background: `url(${image2})`,
          backgroundColor: 'rgba(244, 243, 235, 1)',
          backgroundSize: 'auto 100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          borderColor: goldColor,
          borderStyle: "solid",
          borderWidth: "2px"
        }}>
          <span style={{ position: 'absolute', right: '19px', marginTop: '3px' }}>
            בס״ד
          </span>
          <span
          style={{
              paddingTop: '30px',
            fontFamily: 'Great Vibes',
            fontSize: '3em',
            display: 'flex',
            justifyContent: 'center',
            color: "#ba9c18",
          }}
        >
            Houppa
        </span>
        <div className="flex flex-col items-center gap-2" style={{ fontFamily: 'addingtoncf-thin' }}>
          <div className="flex gap-5 mb-2 justify-center" style={curvedTextStyles}>
              <img className="ml-3" src={image} alt="chateau" />
          </div>

            <div className="flex justify-between mt-[-10px] gap-[20px]">
            <div className="">
                <span>Mme Esther Soudry</span>
                <div> M et Mme Harry Soudry</div>
            </div>
            <div className="text-left">
              <div id="invitation-content">
                  <span>&nbsp;</span>
                  <div>M et Mme Nessim Dray</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-5 m-4 justify-center" style={{ fontFamily: 'addingtoncf-thin' }}>
            <div className="flex justify-center">
              <span className='flex text-center self-center max-w-[300px]'>
                Sont heureux de vous convier au mariage de leurs petits enfants et enfants
              </span>
            </div>
          </div>
        <div className="flex gap-5 text-center items-center justify-center" style={bigGoldTextStyles}>
          <div className='flex text-center self-center'>
              <span style={{ fontFamily: 'Dancing Script', fontSize: "40px", fontWeight: 'bold' }} > Gabriel </span>

          </div>
          <div className='flex text-center self-center'>
              <span style={{ fontFamily: 'Dancing Script', fontSize: "40px" }}>et</span>
          </div>
          <div className='flex text-center self-center'>
              <span style={{ fontFamily: 'Dancing Script', fontSize: "40px", fontWeight: 'bold' }} > Anaël</span>
          </div>
        </div>

        {/* Add the Hebrew names below */}
          <div className="flex gap-[69px] text-center items-center justify-center" style={{ fontFamily: 'addingtoncf-thin', color: goldColor }}>
            <div className='flex text-center self-center'>
              <span style={{ fontWeight: 'bold', fontSize: "20px" }}>גבריאל עמרם</span>
            </div>
            <div className='flex text-center self-center'>
              <span style={{ fontWeight: 'bold', fontSize: "20px" }}>ענאל מרים</span>
            </div>
        </div>
          <div className='honor'>
            <div style={{ fontFamily: 'addingtoncf-thin', maxWidth: '340px' }}>
            Et seraient honorés de votre présence à la houppa ainsi qu'à la récéption qui suivra qui auront lieu ב״ה
          </div>
          </div>
          <div className='flex text-center self-center justify-center pt-3' style={{ fontFamily: 'addingtoncf-thin' }}>
            <span className="font-bold"> Mercredi 10 Juillet&nbsp;</span>2024 à&nbsp;<span className="font-bold">17h00&nbsp;</span>
          </div>
          <div className='flex text-center self-center font-bold justify-center' style={{ fontFamily: 'addingtoncf-thin' }}>
            Au salon Factory 58
          </div>
          <div className='flex text-center self-center justify-center' style={{ fontFamily: 'addingtoncf-thin' }}>
            12 Rue Gutenberg, 93000 Bobigny
          </div>
          <div className="flex justify-center items-center mt-[10px]" style={{ fontFamily: 'addingtoncf-thin' }}>
            <div className="text-center max-w-md italic">
              En ce jour solennel, nous aurons une pensée émue pour nos proches disparus
            </div>
          </div>
          <div className="flex gap-5 mt-[0px] justify-center" style={{ fontFamily: 'addingtoncf-thin' }}>
            <span className='flex text-center self-center italic' style={{ fontFamily: 'Dancing Script', fontSize: '19px' }}>
            Une tenue tsniout réjouira les mariés
          </span>
        </div>
        </div>
      </div>
      <BlackBar />
    </>
  );
}
