// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTm2CvF11MQvhEJHYIZWBi7IGMMnrAkuc",
  authDomain: "anael-382315.firebaseapp.com",
  projectId: "anael-382315",
  storageBucket: "anael-382315.appspot.com",
  messagingSenderId: "301742160289",
  appId: "1:301742160289:web:c37ec1ec26350bfcaab3e0",
  measurementId: "G-8RD2V20H99"
};

// Initialize Firebase Auth provider
const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: "select_account "
});
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const auth = getAuth();

// Initialize Firebase
export const db = getFirestore(app);
