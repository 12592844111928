import React from 'react';

type BlackBarProps = {
  width?: string;
  height?: string;
  backgroundColor?: string;
  marginTop?: string;
  marginBottom?: string;
};

function BlackBar({
  width = '30%',
  height = '1px',
  backgroundColor = 'black',
  marginTop = '15px',
  marginBottom = '10px ',
}: BlackBarProps) {
  const barStyle = {
    height,
    backgroundColor,
    width,
    margin: '0 auto',
    marginTop,
    marginBottom,
  };

  return <div style={barStyle}></div>;
}

export default BlackBar;
