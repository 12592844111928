import React from 'react';

const PreviewImage: React.FC = () => {
  let imageUrl = 'https://weddinganaelgabriel.blob.core.windows.net/imagespreview/preview.jpeg'; // Default preview image URL


  // Get the current pathname from the window location
  const currentPath = window.location.pathname;

  // Set different preview image URLs based on different URLs
  if (currentPath.includes('/henne')) {
    imageUrl = 'https://weddinganaelgabriel.blob.core.windows.net/imagespreview/moroccan-jewish-wedding-e1587643891181.jpg';
  }

  return (
    <meta property="og:image" content={imageUrl} />
  );
};

export default PreviewImage;
